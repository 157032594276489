import App from 'next/app'
import { StyledEngineProvider, SunbeamThemeProvider } from '@achieve/sunbeam'
import CssBaseline from '@mui/material/CssBaseline'
import Layout from 'components/Layout'
import { CacheProvider } from '@emotion/react'
import { createEmotionCache } from 'utils/emotion'
import { AnalyticsProvider } from 'providers/AnalyticsProvider'
import { FeatureFlagProvider } from 'providers/FeatureFlagProvider'
import { DTIProvider } from 'providers/DTIProvider'

import getConfig from 'next/config'

// Using global styling to apply style to third-party components
import './global.scss'
import { useRouter } from 'next/router'

// Specific fonts for Achieve.com public site
import './fonts.scss'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'
import '@contentful/live-preview/style.css'

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  publicProcessDotEnv,
  pageProps,
  host,
  hostProtocol,
}) {
  const { publicRuntimeConfig } = getConfig()
  const router = useRouter()
  const asPath = router.asPath.split('?')[0]
  const canonicalUrlDefault = `${hostProtocol}${host}${asPath === '/' ? '' : asPath}${
    router?.query?.page > 1 ? '?page=' + router.query.page : ''
  }`
  const pageTitle = `${pageProps?.content?.fields?.pageMetaTitle}${
    router?.query?.page > 1 ? ' page ' + router.query.page : ''
  }`

  return (
    <ContentfulLivePreviewProvider
      locale="en-US"
      enableInspectorMode={pageProps.draftMode}
      enableLiveUpdates={pageProps.draftMode}
    >
      <AnalyticsProvider config={publicRuntimeConfig}>
        <CacheProvider value={emotionCache}>
          <StyledEngineProvider injectFirst>
            <CssBaseline />
            <SunbeamThemeProvider>
              <FeatureFlagProvider featureToggleObject={pageProps.featureToggleObject || {}}>
                <Layout
                  publicProcessDotEnv={publicProcessDotEnv}
                  metaDescription={pageProps?.content?.fields?.metaDescription}
                  pageMetaData={pageProps?.content?.fields?.pageMetaData}
                  pageMetaTitle={pageTitle}
                  pageTitle={pageProps?.content?.fields?.pageTitle}
                  canonicalLink={
                    pageProps?.content?.fields?.canonicalLinkOverride || canonicalUrlDefault
                  }
                  pageDisclosure={pageProps?.content?.fields?.pageDisclosure}
                  secondaryDisclosures={pageProps?.content?.fields?.secondaryDisclosures}
                  featureToggleObject={pageProps.featureToggleObject}
                  isFFAMReferrer={pageProps?.isFFAMReferrer}
                  ffamReferrerContent={pageProps?.ffamReferrerContent}
                  header={pageProps?.header}
                  footer={pageProps?.footer}
                  emergencyBanners={pageProps?.emergencyBanners || []}
                >
                  <DTIProvider>
                    <Component {...pageProps} />
                  </DTIProvider>
                </Layout>
              </FeatureFlagProvider>
            </SunbeamThemeProvider>
          </StyledEngineProvider>
        </CacheProvider>
      </AnalyticsProvider>
    </ContentfulLivePreviewProvider>
  )
}

const getObjectOfPublicEnvs = () => {
  // Get properties from process.env that have keys that start with NEXT_PUBLIC_
  const publicEnvs = Object.entries(process.env).filter(([key]) => key.startsWith('NEXT_PUBLIC_'))
  // Return an object with the keys and values of the public envs
  return publicEnvs.reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {})
}

// This is required to inject environment variables client side after build time.
// NOTE that this also disables automatic static optimization
MyApp.getInitialProps = async (props) => {
  // Get tealium URL from env var. Typically this would be inlined at build time but we do a single build.
  const publicProcessDotEnv = getObjectOfPublicEnvs()
  const referer = props?.ctx?.req?.headers?.referer
  const initProps = await App.getInitialProps(props)
  const host = props?.ctx?.req?.headers?.host
  const protocol =
    props?.ctx?.req?.headers['x-forwarded-proto'] || props?.ctx?.req?.connection?.encrypted
      ? 'https://'
      : 'http://'
  // Return our initial props and our new props
  return { ...initProps, publicProcessDotEnv, referer, host, hostProtocol: protocol }
}

export default MyApp
